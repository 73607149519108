.health {
  // background-color: #fff;

  &__content {
    padding: 60px 60px;

    @include respond(phone) {
      padding: 10px 10px;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 40px 0 7px;
  }
  &__icon {
    font-size: 25px;

    @include respond(phone) {
      font-size: 15px;
    }
    &--pin {
      color: #ee4445;
    }
  }

  &__title {
    background-color: transparent;
    color: #212529;
    font-size: 25px;
    margin: 40px 0;
    padding: 10px 35px;
    padding-right: 10px;
    width: fit-content;
    font-weight: bold;

    &--main {
      font-size: 35px;
    }

    @include respond(phone) {
      font-size: 19px;
      width: 100%;
      text-align: center;
      padding: 0px 0px;
      margin: 25px 0 5px;
    }
  }
  &__subtitle {
    display: block;
    color: #212529;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
    flex: 1;

    @include respond(phone) {
      font-size: 17px;
    }
  }

  &__text {
    display: block;
    text-align: justify;
    margin: 15px 0;
    margin-left: 35px;

    @include respond(phone) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
