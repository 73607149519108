.donation {
  &__wrapper {
    display: flex;
    align-items: center;
    // background-color: red;
    // background: url("../../asset/donation-bg.jpg");
    height: 100vh;
  }
  &__card {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    height: 600px;
    border: 1px solid #eee;
    padding: 40px 20px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    margin: 0 0 0 20px;
    margin-left: 10%;

    @include respond(phone) {
      margin: auto;
      width: 90%;
    }
  }

  &__header {
    flex: 0.5;
    font-weight: 900;
    line-height: 1.2em;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;

    @include respond(phone) {
      font-size: 18px;
    }
  }

  &__options {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    flex-wrap: wrap;
  }

  &__box {
    width: 30%;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #eae4e4;
    font-family: "Lota-Regular";
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    color: #000;
    cursor: pointer;
    font-size: 19px;
    font-weight: bold;
    display: inline-block;
    letter-spacing: 1px;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    transition: all 0.1s linear;
    word-break: break-all;

    &:hover {
      background-color: #67b927;
      color: #fff;
    }
    &.active {
      background-color: #67b927;
      color: #fff;
    }
  }

  &__input {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    font-family: "Lota-Light";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.1em;
    padding: 14px 12px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    span {
      margin-right: 10px;
      font-size: 20px;
    }

    input {
      font-size: 20px;
      outline: none;
    }
  }

  &__btn {
    background-color: #67b927;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    padding: 12px 10px;
    border-radius: 5px;
    width: 40%;
    text-align: center;
    margin: auto;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}
