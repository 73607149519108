.hero1 {
  width: 100%;
  height: 85vh;

  @include respond(phone) {
    height: fit-content;
  }

  &__frame {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    @include respond(phone) {
      object-fit: contain;
    }
  }

  &__title {
    position: absolute;
    background-color: #11101077;
    color: #d7d7d7;
    z-index: 10;
    font-size: 30px;
    bottom: 120px;
    padding-left: 50px;
    padding-right: 20px;
    text-transform: capitalize;
  }

  &__subtitle {
    position: absolute;
    background-color: #11101077;
    color: #d7d7d7;
    z-index: 10;
    font-size: 30px;
    bottom: 50px;
    padding-left: 50px;
    padding-right: 20px;
    text-transform: capitalize;
  }
}
