.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  //   justify-content: center;
  padding-bottom: 3rem;
  &__box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 120px;
    // border: 1px solid #eee;
    width: 35%;
    align-self: center;
    @include respond(phone) {
      width: 95%;
    }
  }
  &__option {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    // padding: 10px 10px;
    // border: 1px solid #cccbcb;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cccbcb;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }
  &__description {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
    p {
      color: #6b6b6b;
      font-size: 12px;
    }
    // justify-content: space-around;
  }
  &__icon {
    color: #6b6b6b;
    font-size: 20px;
    &--active {
      color: #1a6ed1;
    }
  }
  &__image {
    img {
      width: 120px;
    }
  }
  &__body {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 0 0;
    transition: all 0.5 ease-out;
    border: 1px solid #cccbcb;
    border-top: none;
    animation: slide-in-top 0.1s ease;
    animation-fill-mode: forwards;
    text-transform: capitalize;
    &--hide {
      display: none;
    }
    &--show {
      display: block;
    }

    label {
      width: 100%;
      @include respond(phone) {
        width: 30%;
        font-size: 13px;
      }
    }

    input {
      background-color: transparent;
      border: 1px solid #cccbcb;
      width: fit-content;
      padding: 10px;
      border-radius: 4px;
      width: 100%;
      justify-self: flex-start;
      margin: 3px 0;
      &::placeholder {
        white-space: pre-wrap;
        word-break: break-word;
      }
      @include respond(phone) {
        width: 100%;
        font-size: 13px;
      }
    }
  }

  &__information {
    margin: 1rem 0;
  }

  &__upload {
  }

  &__btn {
    background-color: #67b927;
    color: #fff;
    font-weight: bold;
    margin: 20px 0;
    padding: 12px 10px;
    border-radius: 5px;
  }
}
