.excos__card {
  min-height: fit-content;
  height: 400px;
  display: flex;
  flex-direction: column;

  &--image {
    flex: 8;
    overflow-y: hidden;
    img {
      width: 100%;
      // height: 70%;
    }
  }
  &--info {
    flex: 1;
  }
}
