@import url(" https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  overflow-x: hidden;
  background: #d7d7d7;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.login-page {
  /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(246, 249, 246, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(246, 249, 246, 0.26);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.required:before {
  content: "*";
  font-weight: bold;
  color: red;
  float: right;
}

.otp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 20px 0;
}

.otp__input:focus-visible {
  border: 2px solid #eee;
  outline: none;
}

.confirmationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmationPage h2 {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.confirmationPage img {
  width: 150px;
}
.confirmationPage .item__row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

.vision__p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
