.uploadModal {
  &__input {
    margin-top: 12px;
    border: 1px solid gray;
    padding: 7px;
    border-radius: 5px;
    width: 40%;
    @include respond(phone) {
      width: 100%;
    }
    &::placeholder {
      font-size: 16px;
    }
    &:focus {
      outline: none;
    }
  }
  &__btn {
    width: 100px;
    background-color: gray;
    color: #fff;
    padding: 1rem 0.5rem;
    border-radius: 5px;
    @include respond(phone) {
      width: 40%;
    }
  }
}
