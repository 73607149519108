.loadingComp {
  position: absolute;
  background-color: #00000073;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  color: #fff;
  font-weight: 500;
  backdrop-filter: blur(5px);
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}
