.otp {
  &__wrapper {
    &--exit {
      animation: slide-out-left 1s ease-out;
      animation-fill-mode: forwards;
      // display: none;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  &__close {
    cursor: pointer;
  }

  &__body {
    &--verify {
      display: flex;
      justify-content: center;
    }
  }

  &__input {
    background-color: #fff;
    width: 40px !important;
    height: 40px;
    border: 2px solid #cac8c8;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    &--code {
      align-items: flex-start;
    }
    &--verify {
      align-items: center;
    }
  }
}
